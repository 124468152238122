.category{
    margin: 40px;
    height: auto;
    /* width: 70%; */
    border:1px solid rgb(2, 56, 43);
    border-radius: 10px;
}

.title{
    width: fit-content;
    margin: -15px 3rem 0 5px;
    padding: 0 0.4rem;
    background:white;
}

@media screen and (max-width: 414px) {
    .category{
        margin: 2rem 0 0 0;
        height: auto;
        border: none;
        border-radius: none;
    }
    .title{
        width: 100%;
        margin: 1rem 0 0 0;
        padding: 0.5rem;
        background:rgb(230, 230, 230);
        border-top: solid 1px black;
        border-bottom: solid 1px black;
        border-radius: none;
    }
}