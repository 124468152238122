.title{
    text-align: center;
    margin-bottom: 3rem;
}
.message{
    padding-top: 0.5rem;
	font-size: 1rem;
}
.error {
	color: #bb0000;
}
.success {
	color: #00bb3e;
}
.container{
    padding: 2rem 15rem;
}
.saveBtn{
    margin-top: 2rem;
}
.checkBoxLabel{
    padding-right: 1.5rem;
    font-size: small;
}