.manegeBox{
    margin: 3rem;
    height: fit-content;
    padding: 3rem;
    width: 100;
    border:1px solid rgb(2, 56, 43);
    border-radius: 10px;
}
@media screen and (max-width: 414px) {
    .manegeBox{
        margin: 0;
        height: inherit;
        padding: 1rem;
        width: 100;
        border:none;
        border-radius: none;
    }
 }