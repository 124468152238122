.header{
    padding-bottom: 1rem !important;
}
.btnDiv{
    padding: 0.4rem 0 !important;
}
.btn{
    padding: 0.4rem 1.5rem !important;

}
.editBtns{
    margin-left: 2rem;
    /* padding-right: 25% !important; */
}
.Addbtn{
    background-color: #ce4aba !important;
    border-color: #ce4aba !important;
}
.Addbtn:hover{
    background-color: #cf1bb4 !important;
    border-color: #cf1bb4 !important;
}
.table{
    height:80vh !important;
}
.thead{
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1 
}

.activeRow{
    background-color: rgb(253, 246, 232) !important;
}
.activeColum{
    background-color: rgb(194, 194, 194) !important;
}
.ColumUp::after{
    padding-right: 10px;
    content: '🠕';
}
.ColumDown::after{
    padding-right: 10px;
    content: '🠗';
}
.closeButton{
    margin: 0 !important;
}
.deleteContainer{
    margin: 2rem !important;
}
.deleteBtns{
    margin-left: 5% !important;
    padding: 1% 12% !important;
}

@media screen and (max-width: 414px){
    .search{
        width: 95% !important;
        margin: 0 0.5rem;
    }
    .btnDiv{
        display: inline-block !important;
        margin: 0.5rem !important;
        padding: 0.4rem 0 !important;
    }
    .maxRows{
        display: flex !important;
        width: fit-content !important;
        margin: 1rem 0.5rem 0 !important;
        height: 2rem;
    }
    .maxRowsTitle{
        width: fit-content;
        white-space: nowrap;
        margin: 0.4rem;
    }
}

