.container{
    margin: 3rem;
    height: fit-content;
    padding: 3rem;
    width: 100;
    border:1px solid rgb(2, 56, 43);
    border-radius: 10px;
    background-color: rgb(240, 237, 233);
}
.linkStyle{
    cursor: pointer;
}
.card{
    margin-right: 20% !important;
    width: 40%;
    font-family: Assistant,Arial;
    color: #808080;
}
.formFields{
    padding: 5% !important;
}

@media screen and (max-width: 414px) {
    .container{
        margin: 1.5rem;
        padding: 1rem;
    }
    .card{
        margin: 0 !important;
        width: 100%;
    }
}