.mainDiv{
    position: absolute;
    padding: 0;
    margin: 0;
    width: 100vw;
    background-color: rgb(199, 199, 199);
    height: 100;
}
.felids {
    margin: 25vh 37vw;
    padding: 3rem 3rem;
    background-color: white;
    border:1px solid rgb(142 151 148);
    border-radius: 10px;
}
.title{
    text-align: center;
    margin-bottom: 3rem;
}
.loginBtn{
    margin-top: 2rem !important;
}
.badgeLight{
    color: #212529 !important;
    background-color: #dbdbdb;
    margin-top: 20px !important;
    width: 100%;
    cursor: pointer
}
.smallBtns{
    width: 100%;
}
.error {
	font-size: 0.8rem;
	color: #bb0000;
}

@media screen and (max-width:1280px) {
    .felids {
        margin: 20vh 30vw;
        padding: 3rem 5rem;
    }
    .mainDiv{
        height: 110vh;
    }

}
@media screen and (max-width: 414px) {
    .mainDiv{
        padding: 0;
        margin: 0;
        background-color:inherit
    }
    .felids {
        margin: 5rem 0 0 0;
        padding: 3rem 3rem;
        border: none;
        border-radius: 0;
    }
    .title{
        text-align: center;
        margin-bottom: 3rem;
    }
    .loginBtn{
        margin-top: 2rem !important
    }
    .badgeLight{
        color: #212529 !important;
        background-color: #dbdbdb;
        margin-top: 20px !important;
        width: 100%;
        cursor: pointer
    }
    .smallBtns{
        width: 100%;
    }

}