.dropdownTextAlign{
    text-align: right;
}
.navbar{
    margin-left: 3rem;
}
.bg{
    background-color:#e7e6e1be;
    position: sticky !important;
    top: 0;
    z-index: 1
}
.responsiveHead{
    height: initial;
    cursor: pointer;
}
.responsiveDonate {
    position: absolute;
    visibility: hidden;
}

@media screen and (max-width: 414px) {
    .bg{
        background-color:#e7e6e1be;
        position: sticky !important;
        top: 0;
        z-index: 1
    }
    .responsiveHead{
        height: 4rem;
    }
    .responsiveTitle{
        margin-right:  2.5rem !important;
    }
    .regularDonate{
        visibility: hidden;
    }
    .responsiveDonate{
        position: relative;
        visibility: visible !important;
        margin: 0 1rem 1rem 0 !important;
    }
    .responsiveNavDrop{
        margin-right:  1.5rem !important;
    }
}